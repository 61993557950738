import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";

class PortfolioIndex extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="mb-6 max-w-2xl" key={post.id}>
              <article className="card large">
                {/* <div className="card-image">
                  <figure className="image">
                    <img
                      src="https://images.unsplash.com/photo-1475778057357-d35f37fa89dd?dpr=1&amp;auto=compress,format&amp;fit=crop&amp;w=1920&amp;h=&amp;q=80&amp;cs=tinysrgb&amp;crop="
                      alt="Image"
                    />
                  </figure>
                </div> */}
                <div className="card-content">
                  <Link
                    className="text-2xl font-semibold"
                    to={post.fields.slug}
                  >
                    {post.frontmatter.title}
                  </Link>
                  <div className="content">
                    {post.excerpt}
                    <br />
                    <br />
                    <Link className="btn" to={post.fields.slug}>
                      View →
                    </Link>
                  </div>
                </div>
              </article>
            </div>
          ))}
      </div>
    );
  }
}

PortfolioIndex.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query PortfolioIndexQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "portfolio-item" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <PortfolioIndex data={data} count={count} />}
  />
);
