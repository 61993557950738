import React from "react";
import Helmet from "react-helmet";
import Layout from "../../components/Layout";
import PortfolioIndex from "../../components/PortfolioIndex";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Portfolio</title>
        </Helmet>
        <section className="section">
          <div className="container mx-auto">
            <h1 className="text-3xl font-semibold mb-5">Latest Updates</h1>
            <div className="content">
              <PortfolioIndex />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
